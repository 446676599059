import {all, call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'
import actions from "src/redux/admin/actions";
import authActions from "src/redux/auth/actions";
import SuperFetch from "src/helpers/superFetch";
import {endpoints} from "src/config/site.config";
import {createCsv, getAge} from "../../helpers/utility";
import dayjs from "dayjs";


export function* getAllUsers() {

    yield takeLatest(actions.GET_ALL_USERS, function* (data) {
        let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.pageSize + "&sort=dateCreated," + data.payload.sort

        if (data.payload.isProfileCompleted !== null) {
            queryString = queryString + "&isProfileCompleted=" + data.payload.isProfileCompleted
        }
        if (data.payload.jobState !== null) {
            queryString = queryString + "&jobState=" + data.payload.jobState
        }
        if (data.payload.name) {
            queryString = queryString + "&name=" + data.payload.name
        }
        if (data.payload.province) {
            queryString = queryString + "&province=" + data.payload.province
        }
        if (data.payload.city) {
            queryString = queryString + "&city=" + data.payload.city.value
        }
        if (data.payload.qualificationLevel) {
            queryString = queryString + "&qualificationLevel=" + data.payload.qualificationLevel
        }

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllUsers + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_USERS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USERS_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllCandidatureByUserId() {

    yield takeEvery(actions.GET_ALL_CANDIDATURE_BY_USER, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCandidatureByUserId + "/" + data.id, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_CANDIDATURE_BY_USER_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_ALL_CANDIDATURE_BY_USER_ERROR,
                payload: error
            })
        }
    })
}

export function* getUserById() {

    yield takeEvery(actions.GET_USER, function* (data) {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getUserById + "/" + data.id, null, true)
        if (response) {
            yield put({
                type: actions.GET_USER_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_USER_ERROR,
                payload: error
            })
        }
    })
}

export function* getAdminDashboard() {
    yield takeEvery(actions.GET_ADMIN_DASHBOARD, function* () {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getSysDashboard, null, true)
        if (response) {
            yield put({
                type: actions.GET_ADMIN_DASHBOARD_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.GET_ADMIN_DASHBOARD_ERROR,
                payload: error
            })
        }
    })
}


export function* getAllAdminCompany() {

    yield takeEvery(actions.GET_ALL_ADMIN_COMPANY, function* (data) {

        let queryString = "?page=" + (data.payload.page - 1) + "&size=" + data.payload.pageSize + "&sort=dateCreated," + data.payload.sort

        if (data.payload.search) {
            queryString = queryString + "&name=" + data.payload.search
        }

        if (data.payload.state && data.payload.state !== "all") {
            queryString = queryString + "&enabled=" + data.payload.state
        }

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllAdminCompany + queryString, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.GET_ALL_ADMIN_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_ADMIN_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* enableCompany() {

    yield takeEvery(actions.ENABLE_COMPANY, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.enableCompany + "/" + data.payload, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.ENABLE_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.ENABLE_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* disableCompany() {

    yield takeEvery(actions.DISABLE_COMPANY, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.disableCompany + "/" + data.payload, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DISABLE_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DISABLE_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* getAdminCv() {
    yield takeEvery(actions.GET_ADMIN_CV, function* (data) {
        const {
            response,
            fileName,
            error
        } = yield call(SuperFetch.post, endpoints.getImageSource, data.payload, true, false, null, true)
        if (response) {
            let blob = yield response.blob()
            let fileUrl = URL.createObjectURL(blob)
            yield put({
                type: actions.GET_ADMIN_CV_SUCCESS,
                payload: fileUrl,
                fileBlob: blob,
                fileName: fileName,
                fileType: response.headers.get("Content-Type")
            });
            let alink = document.createElement('a');
            alink.href = fileUrl;
            alink.download = fileName;
            alink.click();
        } else {
            yield put({
                type: actions.GET_ADMIN_CV_ERROR,
                payload: error
            })
        }
    })
}


export function* deleteCompany() {

    yield takeEvery(actions.DELETE_COMPANY, function* (data) {

        yield put({type: authActions.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.delete, endpoints.deleteCompany + "/" + data.payload, null, true)
        if (response) {
            yield put({type: authActions.LOADING_FALSE});

            yield put({
                type: actions.DELETE_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: authActions.LOADING_FALSE});
            yield put({
                type: actions.DELETE_COMPANY_ERROR,
                payload: error
            });
        }
    })

}

export function* createCompanyCsv() {

    yield takeEvery(actions.CREATE_COMPANY_CSV, function* () {

        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllCompanyForCsv, null, true)

        if (response) {
            yield put({
                type: actions.CREATE_COMPANY_CSV_SUCCESS
            })

            let headers = ["Ragione sociale", "Data registrazione", "Partita IVA", "Città", "Amministratore", "Numero offerte", "Numero candidati", "Attiva", "Numero di telefono", "Email", "Settore"]
            let formattedArray = response.map(it => {
                return {
                    companyName: it.companyName,
                    registrationDate: dayjs(it.registrationDate).format("DD/MM/YYYY"),
                    vatNumber: it.vatNumber,
                    city: it.city ? it.city : "",
                    adminNameSurname: it.adminNameSurname,
                    offertCount: it.offertCount,
                    candidateCount: it.candidateCount,
                    enabled: it.enabled,
                    phoneNumber: it.phoneNumber,
                    email: it.adminEmail,
                    workSector: it.workSector,
                }
            })
            createCsv(formattedArray, headers, "Company_" + dayjs(Date.now()).format("DD_MM_YYYY_HH_mm_ss"))
        }
    })

}

export function* createUsersCsv() {

    yield takeEvery(actions.CREATE_USERS_CSV, function* () {
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.getAllUsersForCsv, null, true)

        if (response) {
            yield put({
                type: actions.CREATE_USERS_CSV_SUCCESS
            })

            let headers = ["Nome", "Cognome", "Email", "Come hai saputo di noi", "Stato lavorativo", "Città", "Provincia", "Cap", "Data iscrizione", "Qualifica", "Età", "Candidature"]

            if (response.length !== 0) {
                let formattedArray = response.map(it => {
                    return {
                        name: it.firstName,
                        surname: it.lastName,
                        email: it.email,
                        howDoYouKnowUs: it.howDoYouKnowUs,
                        jobState: it.jobState,
                        city: it.city,
                        province: it.province,
                        cap: it.cap !== 0 ? it.cap : "",
                        dateCreated: dayjs(it.dateCreated).format("DD/MM/YYYY"),
                        qualificationLevel: it.qualificationList ? it.qualificationList[0].qualificationLevel : "",
                        age: it.birthday !== 0 ? getAge(it.birthday) : "",
                        candidatureCount: it.candidatureCount
                    }
                })
                createCsv(formattedArray, headers, "Users_" + dayjs(Date.now()).format("DD_MM_YYYY_HH_mm_ss"))
            }
        }
    })

}


export default function* rootSaga() {
    yield all([
        fork(getAdminDashboard),
        fork(getAllCandidatureByUserId),
        fork(getUserById),
        fork(getAllUsers),
        fork(getAllAdminCompany),
        fork(enableCompany),
        fork(disableCompany),
        fork(getAdminCv),
        fork(deleteCompany),
        fork(createCompanyCsv),
        fork(createUsersCsv)
    ]);
}

